import hierarchiesService from '../../../v1/js/configs/hierarchies';
import expressions from './expressions';
import { getDynamicSectionsForCurrentContextConfig } from './dynamic-navigation-service';

const BATCH_EXPRESSIONS_FF_NAME = 'GlobalWrapper.EnableBatchExpressions';
const NEW_ACCOUNT_SETTINGS_FF_NAME = 'EAX.DisplayNewAccountSettings';

/*
    Per NAV-545, these tabs are required to be in this order:
      Homepage
      Projects
      Catalog
      Actions
      Directories
      Journeys
      Libraries
      Participant Portal
      Tickets
      Approvals
      Survey Director
      Custom Tabs/Add Ons
      App Configurer
      Admin
      Account Settings
  */
// Ideally this is declarative in a config, but "reusing/merging" multiple contextTypes into a single "Directories" entry prevents that.
function getRootHierarchies(ctx, bundleData) {
  return [
    'Homepage',
    'Project',
    'SchedulingApp',
    'Catalog',
    'GlobalActions',
    'GlobalDatasets',
    getDirectoryContextType(ctx),
    'Journeys',
    'LibraryV3',
    'ParticipantPortal',
    'Tickets',
    'Approvals',
    'SurveyDirector',
    'CustomTabs',
    'PortalsSettings',
    'Extensions',
    'Admin',
    getAccountSettingsContextType(bundleData),
  ];
}

const contextTypesNotToShow = new Set([
  'AccountSettings',
  'AccountSettingsV2',
  'PortalsApp',
]);

const getConfig = (cmp, ctx, bundleData) => {
  return cmp === 'CustomTabs'
    ? hierarchiesService.customtabs(bundleData.user.customTabs)
    : hierarchiesService.getConfig(ctx, true);
};

export const getCurrentTab = (ctx, bundleData, language) => {
  try {
    const currentTabConfig = getConfig(ctx.contextType, ctx, bundleData);
    return updateTabVisibilityAndDynamicNav(
      [currentTabConfig],
      ctx,
      bundleData.features,
      true,
      language,
    ).then(resolveHrefsFromChildren);
    // eslint-disable-next-line no-empty
  } catch (e) {
    return Promise.resolve([]);
  }
};

export const getAllTabs = (ctx, bundleData, language) => {
  const hierarchies = getRootHierarchies(ctx, bundleData);
  const configs = hierarchies.map((name) =>
    getConfig(name, { contextType: name }, bundleData),
  );
  return updateTabVisibilityAndDynamicNav(
    configs,
    ctx,
    bundleData.features,
    false,
    language,
  ).then(resolveHrefsFromChildren);
};

// getDirectoryContextType exists because 3 different applications want to appear as "Directories" in the tier 1 context switcher. We can't easily "merge" them declaratativly, so we use this function to determine with contextType to use under the "Directories" UI.
function getDirectoryContextType(ctx) {
  if (
    ctx.contextType === 'Directory' ||
    ctx.contextType === 'GlobalDirectory'
  ) {
    return ctx.contextType;
  }

  return 'IQDirectory'; // this is the fallback since it has the directorieshome
}

function getAccountSettingsContextType(bundleData) {
  const newAccountSettingsEnabled =
    !!bundleData.features[NEW_ACCOUNT_SETTINGS_FF_NAME] || false;
  return newAccountSettingsEnabled ? 'AccountSettingsV2' : 'AccountSettings';
}

function prepareNonBatchedExpressionsResolver(
  configs,
  ctx,
  useOriginalContext,
) {
  let expressionVisibilityGroups = {};

  configs.forEach(function (cfg) {
    // If we are the current tab, use the full context
    let expsCtx = ctx;
    // otherwise use a partial context
    if (cfg.v2Info.contextType !== ctx.contextType || !useOriginalContext) {
      expsCtx = {
        /* Intentional. For evaluating logic to access different contexts, we should only have the user context + the name. */
        contextType: cfg.v2Info.contextType,
      };
    }

    const exps = expressions.extractExpressions(cfg, cfg.v2Info.id);
    if (exps && Object.keys(exps).length !== 0) {
      expressionVisibilityGroups[cfg.v2Info.id] = expressions
        .evaluate(exps, expsCtx)
        .catch((_) => {}); // On error evaluating visibility expressions don't let it kill execution.
    }
  });
  return expressionVisibilityGroups;
}

function prepareBatchedExpressionsResolver(configs, ctx, useOriginalContext) {
  let expressionVisibilityGroups = {};
  let batchableExpressionsMap = {};

  configs.forEach(function (cfg) {
    // If we are the current tab, use the full context
    let expsCtx = ctx;
    const exps = expressions.extractExpressions(cfg, cfg.v2Info.id);
    if (exps && Object.keys(exps).length !== 0) {
      // otherwise use a partial context
      if (cfg.v2Info.contextType !== ctx.contextType || !useOriginalContext) {
        expsCtx = {
          /* Intentional. For evaluating logic to access different contexts, we should only have the user context + the name. */
          contextType: cfg.v2Info.contextType,
        };
        batchableExpressionsMap[cfg.v2Info.id] = { exps, expsCtx };
      } else {
        expressionVisibilityGroups[cfg.v2Info.id] = expressions
          .evaluate(exps, expsCtx)
          .catch((_) => {}); // On error evaluating visibility expressions don't let it kill execution.
      }
    }
  });
  const batchedExpressionVisibilityGroups =
    Object.keys(batchableExpressionsMap).length > 0
      ? expressions.evaluateBatch(batchableExpressionsMap)
      : {};
  expressionVisibilityGroups = {
    ...expressionVisibilityGroups,
    ...batchedExpressionVisibilityGroups,
  };

  return expressionVisibilityGroups;
}

function updateTabVisibilityAndDynamicNav(
  configs,
  ctx,
  features,
  useOriginalContext,
  language,
) {
  const areBatchExpressionsEnabled = !!features[BATCH_EXPRESSIONS_FF_NAME];
  let expressionVisibilityGroups;
  if (areBatchExpressionsEnabled) {
    expressionVisibilityGroups = prepareBatchedExpressionsResolver(
      configs,
      ctx,
      useOriginalContext,
    );
  } else {
    expressionVisibilityGroups = prepareNonBatchedExpressionsResolver(
      configs,
      ctx,
      useOriginalContext,
    );
  }
  return promiseResolveObject({
    ...expressionVisibilityGroups,
    dynamicSections: getDynamicSectionsForCurrentContextConfig(
      ctx,
      features,
      language,
    ),
  }).then(function (resolvedObject) {
    const { dynamicSections, ...expressionVisibilityGroups } = resolvedObject;
    if (dynamicSections?.dynamicSectionsTabs) {
      const dynamicNavConfigIndex = configs.findIndex(
        (cfg) => cfg.v2Info.contextType === ctx.contextType,
      );
      if (dynamicNavConfigIndex !== -1) {
        configs[dynamicNavConfigIndex].sectionTabs =
          dynamicSections.dynamicSectionsTabs;
        configs[dynamicNavConfigIndex].dynamic = true;
      }
    }

    return configs.map(function (cfg) {
      if (!cfg.dynamic && expressionVisibilityGroups[cfg.v2Info.id]?.hasError)
        return { v2Info: cfg.v2Info, hasError: true };

      return expressions.updateVisibilities(
        cfg,
        expressionVisibilityGroups[cfg.v2Info.id],
        cfg.v2Info.id,
      );
    });
  });
}

function resolveHrefsFromChildren(configs) {
  return configs.map(function (cfg) {
    if (!cfg.hasError) {
      // calculate tab group href BEFORE super tab href
      cfg.sectionTabs = cfg.sectionTabs.map((secTab) => {
        if (Array.isArray(secTab.subTabs)) {
          secTab.href = secTab.subTabs.find(firstVisibleTab)?.href;
        }
        return secTab;
      });

      // calculate super tab href AFTER group tab href above
      cfg.href =
        cfg?.defaultHref ?? cfg.sectionTabs.find(firstVisibleTab)?.href;
    }
    return cfg;
  });
}

function firstVisibleTab(tab) {
  return !!tab.isDynamic || !!tab.visible;
}

// promiseResolveObject defines a function that will do a `Promise.all` for objects.
function promiseResolveObject(object) {
  let promisedProperties = [];
  const objectKeys = Object.keys(object);

  objectKeys.forEach((key) => promisedProperties.push(object[key]));

  return Promise.all(promisedProperties).then((resolvedValues) => {
    return resolvedValues.reduce((resolvedObject, property, index) => {
      resolvedObject[objectKeys[index]] = property;
      return resolvedObject;
    }, object);
  });
}

export const getSections = (superTabs) => {
  return superTabs
    .filter(
      (tab) =>
        !tab?.hasError &&
        tab.visible &&
        !contextTypesNotToShow.has(tab.v2Info.contextType),
    )
    .map((tab) => ({
      contextType: tab.v2Info.contextType,
      nameKey: tab.nameKey,
      href: tab.href,
      iconName: tab.iconName,
      themeType: tab.themeType,
      hideContentUntilMsgFromPortal: tab.hideContentUntilMsgFromPortal,
    }));
};

export const getSection = (contextType, superTabs) => {
  const section = superTabs.find(
    (tab) =>
      tab.v2Info.contextType === contextType && (tab.visible || tab.hasError),
  );

  if (section) {
    const errorObject = section.hasError ? { hasError: true } : {};
    return {
      ...errorObject,
      contextType: section.v2Info.contextType,
      nameKey: section.nameKey,
      href: section.href,
    };
  }
  return undefined;
};

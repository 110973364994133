import {
  AdminGearsIcon,
  ButtonIcon,
  CatalogIcon,
  DatabaseIcon,
  DirectoryIcon,
  HomeIcon,
  IntegrationIcon,
  JourneyMapIcon,
  LibrariesIcon,
  PortalConfigIcon,
  ProjectIcon,
  SurveyDirectorIcon,
  TicketsIcon,
  WorkflowsIcon,
  GlobalCalendarIconIcon,
} from '@qualtrics/ui-react/icons';

export const iconsMapping = {
  Admin: AdminGearsIcon,
  Contacts: DirectoryIcon,
  CustomTabSection: ButtonIcon,
  Catalog: CatalogIcon,
  Directory: DirectoryIcon,
  DirectoryHome: DirectoryIcon,
  Extensions: IntegrationIcon,
  GlobalActions: WorkflowsIcon,
  GlobalDatasets: DatabaseIcon,
  GlobalDirectory: DirectoryIcon,
  Homepage: HomeIcon,
  IQDirectory: DirectoryIcon,
  LibraryV3: LibrariesIcon,
  Journeys: JourneyMapIcon,
  PortalsSettings: PortalConfigIcon,
  Project: ProjectIcon,
  SchedulingApp: GlobalCalendarIconIcon,
  SurveyDirector: SurveyDirectorIcon,
  Tickets: TicketsIcon,
};

import httpClient from '@qualtrics/page-http-client';

const HTTP_CLIENT_SETTINGS = {
  credentials: 'omit',
  applyXsrfHeader: false,
  applyTransactionId: false,
};
const TRANSLATIONS_BASE_URL = '/static/pf-translations';
const TRANSLATIONS_STATIC_ASSETS_BASE_URL =
  'https://static-assets.qualtrics.com/static/pf-translations';

const translationsBaseUrl = (useStaticAssetsDomain) => {
  return useStaticAssetsDomain
    ? TRANSLATIONS_STATIC_ASSETS_BASE_URL
    : TRANSLATIONS_BASE_URL;
};

const fetchSingleSectionTranslations = async (
  language,
  version,
  useStaticAssetsDomain,
) => {
  const sectionResponse = await httpClient.cachedGet(
    `${translationsBaseUrl(useStaticAssetsDomain)}/${language}/${version}.json`,
    HTTP_CLIENT_SETTINGS,
  );
  return sectionResponse.data;
};

export const loadTranslations = async (
  language,
  sections,
  versions,
  useStaticAssetsDomain = false,
) => {
  if (!versions) {
    return null;
  }
  return Promise.all(
    sections.map((sectionName) => {
      return fetchSingleSectionTranslations(
        language,
        versions[sectionName],
        useStaticAssetsDomain,
      );
    }),
  )
    .then((fetchedSections) => {
      return fetchedSections.reduce(
        (translations, current) => ({ ...translations, ...current }),
        {},
      );
    })
    .catch(() => {
      return null;
    });
};

import { sendTractiveMetric } from '@qualtrics/page-metrics/lib/tractiveService';
import JSCookie from 'js-cookie';
import SessionStorageManager from './session-storage-manager';
import cookies, { getCookie } from './cookies';
import http from './http';
import { globalWrapperCache } from './global-wrapper-cache';
import { isPathAvailableInGivenPortal } from '@qualtrics-portals/portal-checker';
import { filterAsync } from '../../utils/filterAsync';
import { getDashboardType } from './dashboards-service';
import { isExportPage } from './tool-check';

const cache = new SessionStorageManager(window);
const portalIdCacheKey = (sessionId) => `portal_id_${sessionId}`;

export const QUALTRICS_ID = 'Qualtrics';
const PARTICIPANT_PORTAL_ID = 'ParticipantPortal';
const DX_PORTAL_ID = 'DXPortal';
const DX_PORTAL_PBA_ID = 'dx-portal';

export const isPortalsApp = (ctx) => {
  return ctx?.contextType === 'PortalsApp';
};

export const isPortalsAppChild = (ctx) => {
  try {
    return !isPortalsApp(ctx) && window.parent.Q_PortalsContext !== undefined;
  } catch {
    // in case we're inside an iframe with cross-origin blocked and we cannot access parent
    return false;
  }
};

/**
 * @param ctx
 * @param {boolean} isLegacyPortalHeader
 * @returns {{currentPortalId?: string, isParticipantPortal: boolean, isPortalsApplication: boolean, isPortalsChild: boolean}}
 */
export const getPortalInfo = (ctx, isLegacyPortalHeader) => {
  const isPortalsApplication = isPortalsApp(ctx);
  const isPortalsChild = isPortalsAppChild(ctx);
  const isParticipantPortal =
    isParticipantPortalApp(ctx) || isLegacyPortalHeader;

  let currentPortalId = QUALTRICS_ID;
  if (isPortalsApplication) {
    currentPortalId = ctx?.resourceId;
  } else if (isParticipantPortal) {
    currentPortalId = PARTICIPANT_PORTAL_ID;
  } else if (isDXPortalApp(ctx)) {
    currentPortalId = DX_PORTAL_PBA_ID;
  }
  return {
    isPortalsApplication,
    isPortalsChild,
    isParticipantPortal,
    currentPortalId,
  };
};

export const notifyAboutContext = (ctx, activeSuperTab) => {
  if (isPortalsAppChild(ctx) && window.parent.Q_PortalsContext.onContextLoad) {
    window.parent.Q_PortalsContext.onContextLoad(ctx, activeSuperTab);
  }
};

export const getPortalId = (ctx) => {
  if (isPortalsApp(ctx)) {
    return ctx.resourceId;
  }
  if (isPortalsAppChild(ctx)) {
    return window.parent.Q_PortalsContext.portalId;
  }
  return undefined;
};

/*
  Used only for defining if we should try to redirect user to a portal when loading GW outside of portal context
  Currently also used for AccountMenu to hide current portal
  And in Account Settings to determine if we should display simplified navigation
 */
export const getPortalIdFromCookie = (userId) => {
  if (!userId) return null;
  return getCookie(`${userId.toUpperCase()}_PORTAL_MODE`);
};

export const getAvailablePortals = async (language) => {
  const portalsUrl = `/portals/api/available-portals?includeURLLists=true&lang=${language}&includeExternalPortals=true`;
  const sessionId = cookies.get('UDSSessionKey');
  const key = `${portalsUrl}&UDSSessionKey=${sessionId}`;
  const cacheData = cache.getSessionStorage(key);
  if (cacheData) {
    return JSON.parse(cacheData).portals;
  } else {
    const availablePortals = http.getJSON(portalsUrl);
    return availablePortals
      .then((data) => {
        if (!data || !data.portals) {
          return [];
        }
        cache.setSessionStorage(key, JSON.stringify(data));
        return data.portals;
      })
      .catch((res) => {
        let message = 'Cannot get available portals';
        if (res) {
          message = res.status + ' ' + res.statusText;
        }
        console.error(message);
        return [];
      });
  }
};

export const storePortalId = (portalId, userId) => {
  const sessionId = cookies.get('UDSSessionKey');
  cache.setSessionStorage(portalIdCacheKey(sessionId), portalId);
  if (userId) {
    const cookieName = `${userId.toUpperCase()}_PORTAL_MODE`;
    const cookie = getCookie(cookieName);
    if (cookie !== portalId) {
      JSCookie.set(cookieName, portalId, { secure: true, expires: 365 });
    }
  }
};

export const getStoredPortalId = () => {
  const sessionId = cookies.get('UDSSessionKey');
  return cache.getSessionStorage(portalIdCacheKey(sessionId));
};

export const switchPortalFromMenu = (targetPortalId, currentPortalId) => {
  sendTractiveMetric({
    billingTeam: 'EUA',
    eventName: 'App.Switch',
    customAttributes: {
      portalId: currentPortalId,
      switchTo: targetPortalId,
    },
  });
  storePortalId(targetPortalId);
};

export const emitAppLoadEvent = (portalId, portals) => {
  const currentPortalId = getStoredPortalId();
  if (currentPortalId !== window.parent.Q_PortalsContext?.portalId) {
    // if config not loaded yet, try again in 100ms (config is needed to define if custom logo and theme used)
    // eslint-disable-next-line angular/timeout-service
    return setTimeout(() => emitAppLoadEvent(portalId, portals), 100);
  }
  if (portalId !== 'Qualtrics') {
    const availablePortalIds = portals
      ? portals.map((portal) => portal.id)
      : [];
    sendTractiveMetric({
      billingTeam: 'EUA',
      eventName: 'App.Load',
      customAttributes: {
        portalId: portalId,
        availablePortalIds,
        hasCustomLogo: !!window.parent.Q_PortalsContext?.portalData?.logoUrl,
        hasCustomTheme:
          !!window.parent.Q_PortalsContext?.portalData?.headerColor,
      },
    });
  }
};
export const getSwitchPortalUrl = (portal, currentPortalId, portals) => {
  if (portal.portalType === 'EXTERNAL') {
    return portal.homeHref;
  }

  if (
    portal.id === QUALTRICS_ID &&
    currentPortalId === PARTICIPANT_PORTAL_ID &&
    !portals.find((portal) => portal.id === PARTICIPANT_PORTAL_ID)
  ) {
    // when switching to qualtrics from participant portal when there's no participant portal available
    // force a switch to homepage
    return `/portals/api/switch-portal?portalId=${portal.id}`;
  }
  return getRedirectPortalUrl(portal.id);
};

export const getRedirectPortalUrl = (portalId, path) => {
  const link = window.location.href;
  const pathParam = path || link.replace(window.location.origin, '');
  const encodedPathParamPart = pathParam
    ? `&path=${encodeURIComponent(pathParam)}`
    : '';
  return `/portals/api/switch-portal?portalId=${portalId}${encodedPathParamPart}`;
};

export const redirectToPortal = (portalId, path, userId) => {
  storePortalId(portalId, userId);
  window.location.href = getRedirectPortalUrl(portalId, path);
};

export const isParticipantPortalApp = (ctx) => {
  return ctx?.contextType === PARTICIPANT_PORTAL_ID;
};

export const isDXPortalApp = (ctx) => {
  return ctx?.contextType === DX_PORTAL_ID;
};

export const isParticipantPortalPage = (pageId) => {
  return pageId === PARTICIPANT_PORTAL_ID;
};

export const selectPortalAndRedirect = (
  portalsValidForPath,
  path,
  portals,
  userId,
) => {
  const selectedPortal = portalsValidForPath[0];

  if (selectedPortal.id === QUALTRICS_ID) {
    storePortalId(QUALTRICS_ID, userId);
  } else {
    redirectToPortal(selectedPortal.id, path, userId);
  }
};

export const checkIfPortalIsValidAndRedirectIfNeeded = (
  portalIdToCheck,
  portalsValidForPath,
  path,
  portals,
  userId,
  isPortalsRoadblockEnabled = false,
) => {
  if (portalsValidForPath.some((portal) => portal.id === portalIdToCheck)) {
    if (portalIdToCheck === QUALTRICS_ID) {
      storePortalId(QUALTRICS_ID, userId);
    } else {
      globalWrapperCache.removeAllPortalConnectedItems();
      redirectToPortal(portalIdToCheck, path, userId);
    }
    return;
  }
  if (isPortalsRoadblockEnabled && portalsValidForPath.length > 1) {
    window.location.replace(
      `/portals/chooser/ui?path=${encodeURIComponent(
        path,
      )}&displayRoadblock=true`,
    );
  } else {
    selectPortalAndRedirect(portalsValidForPath, path, portals, userId);
  }
};

export const checkIfPageIsValidInPortal = async (
  portals,
  context,
  userId,
  isRestrictedNavigation,
  pageId,
  isPortalsRoadblockEnabled,
) => {
  if (isRestrictedNavigation || !portals || !getPortalIdFromCookie(userId)) {
    // shared user account, no portals support for them
    return;
  }
  if (isPortalsAppChild(context)) {
    // no action required for portals frames
    return;
  }
  if (isParticipantPortalApp(context) || isParticipantPortalPage(pageId)) {
    // for now treat ParticipantPortal as a special case of portal and don't redirect away from it in any case
    return;
  }
  if (isDXPortalApp(context)) {
    // for now treat DXPortal as a special case of portal and don't redirect away from it in any case
    return;
  }
  if (isExportPage()) {
    // export page should always be open in BigQualtrics mode as it's settings fields on window
    return;
  }
  if (isPortalsApp(context)) {
    // portals app, store current portal id
    storePortalId(getPortalId(context), userId);
    emitAppLoadEvent(getPortalId(context), portals);
    return;
  }
  const fullPath = window.location.href.replace(window.location.origin, '');

  const portalsValidForPath = await filterAsync(
    portals,
    async (portal) =>
      portal.portalType !== 'EXTERNAL' &&
      (await isPathAvailableInGivenPortal(portal, fullPath, (dashboardId) =>
        getDashboardType(dashboardId, cache),
      )),
  );

  if (portalsValidForPath.length === 0) {
    sendTractiveMetric({
      billingTeam: 'EUA',
      eventName: 'App.BigQRedirect',
      customAttributes: {
        action: 'redirect',
        portalId: getStoredPortalId(),
        destinationPath: fullPath,
      },
    });
    return;
  }
  const storedPortalId = getStoredPortalId();
  if (storedPortalId) {
    checkIfPortalIsValidAndRedirectIfNeeded(
      storedPortalId,
      portalsValidForPath,
      fullPath,
      portals,
      userId,
      isPortalsRoadblockEnabled,
    );
  } else {
    const cookiePortalId = getPortalIdFromCookie(userId) || QUALTRICS_ID;
    checkIfPortalIsValidAndRedirectIfNeeded(
      cookiePortalId,
      portalsValidForPath,
      fullPath,
      portals,
      userId,
      isPortalsRoadblockEnabled,
    );
  }
};
